import { useEffect, useState } from "react";
import ImageCropper from "../global/imageCropper";
import bannerLogo from '../../assets/images/PCGC_Login_logo.png';
import defaultlogo from '../../assets/images/defaultlogo.jpg';
import FormFooter from "./formFooter";
import * as helper from "../global/helper"; 
import SignatureModal from "./signatureModal";

const PrimaryForm = (props) => {

    const aadharPattern = /^[0-9]{12}$/;
    const mobilePattern = /^[0-9]{10}$/;

    const {primaryDetails , setSignatureImg, updatePrimaryDetails, primaryPhotoBlob, setPrimaryPhotoBlob, signaturePhotoBlob, setSignaturePhotoBlob, groupDetails, updateGroupDetails, panError, accessDetails} = props;
    const [cropperData,                 setCropperData]                      = useState(null);
    const [signatureCropperData,        setSignatureCropperData]             = useState(null);
    const [genderOption,                setGenderOption]                     = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [bloodGroupOption,            setBloodGroupOption]                 = useState([{ value:  'A+', label: 'A+'}, { value:  'O+', label: 'O+'},{ value:  'B+', label: 'B+'}, { value:  'AB+', label: 'AB+'}, { value:  'A-', label: 'A-'}, { value:  'O-', label: 'O-'}, { value:  'B-', label: 'B-'}, { value:  'AB-', label: 'AB-'}]);
    const [showModal,                   setShowModal]                        = useState(false);

    useEffect(() => {
        if(groupDetails?.MemberSignature || signaturePhotoBlob){
            setSignatureImg(groupDetails?.MemberSignature || signaturePhotoBlob)
        }
    }, [groupDetails?.MemberSignature, signaturePhotoBlob])

    const openCropper = async(e) =>{
        e.preventDefault()
        setCropperData({
            imageRatio  : { width : 130, height : 140},
            dataHandler : (val) =>  setPrimaryPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    };

    const openSignatureCropper = async(e) =>{
        e.preventDefault()
        $('#modalSignature').addClass('modal-blur');
        setSignatureCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setSignaturePhotoBlob(val),
            header : 'Upload Signature Photo'
        })
    };

    const addSignatureName = () => {
        const signName = primaryDetails?.FirstName + ' ' + primaryDetails?.LastName
        updateGroupDetails('MemberSignature',  signName)
    };

    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setPrimaryPhotoBlob(null)
        updatePrimaryDetails('ProfilePhoto',  '')
	};

    const removeSignatureHandler = async(e) => {
		e.preventDefault();
		setSignaturePhotoBlob(null)
        setSignatureImg(null)
        updateGroupDetails('MemberSignature',  '')

	};

    // Open the modal
    const handleOpenModal = (e) => {
        e.preventDefault()
        setShowModal(true);
    };
         
    return(
        <>
            <div className="panel panel-paper">
                <div className="panel-body form-onboarding">
                    <div className="panel-heading text-center" style={{marginBottom: '20px'}}>
                        {/* Logo and club name */}
                        <img className="img-responsive img-login margin-b10" src={bannerLogo} alt="" style={{height: '80px'}}/>
                        <h3 className="panel-title mb-0 padding-t0"><strong>The Poona Club Ltd (Golf), MEMBERSHIP AGREEMENT</strong></h3>
                        <h5 className=" mb-0 padding-t5"><strong>{groupDetails?.MembershipType}</strong></h5>
                    </div>
                    <fieldset className="primary">
                        <legend > Primary Details</legend>
                        {groupDetails?.Category == 'Organization' && (
                            <div className="form-group">
                                <label >Company Name <span className="invalid-feedback">*</span></label>
                                <input type="text" className="form-control" id="txtCompanyName" name="CompanyName" value={groupDetails.CompanyName || ''} onChange={(event) => updateGroupDetails('CompanyName', event.target.value)}/>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label >First Name <span className="invalid-feedback">*</span></label>
                                    <input type="text" className="form-control" id="inputFirstName1"value={primaryDetails.FirstName || ''} onChange={(event) => updatePrimaryDetails('FirstName',  helper.camelCase(event.target.value))}/>
                                </div>
                                <div className="form-group">
                                    <label >Middle Name</label>
                                    <input type="text" className="form-control" id="inputMiddleName1"value={primaryDetails.MiddleName || ''} onChange={(event) => updatePrimaryDetails('MiddleName',  helper.camelCase(event.target.value))}/>

                                </div>
                                <div className="form-group">
                                    <label>Last Name <span className="invalid-feedback">*</span></label>
                                    <input type="text" className="form-control" id="inputLastName1"value={primaryDetails.LastName || ''} onChange={(event) => updatePrimaryDetails('LastName',  helper.camelCase(event.target.value))}/>

                                </div>
                            </div>
                            {/* Logo on the right side */}
                            <div className="col-lg-3">
                                <div className="text-center" style={{ margin: '73px 42px auto' }}>
                                    {/* Image uploader */}
                                    <div className="kt-avatar kt-avatar--outline kt-avatar--changed offset-4" id="kt_user_add_avatar">
                                        {/* Edit button */}
                                        <div className="edit-icon" onClick={openCropper}>
                                            <div className="pencil-container">
                                                <i className="fa fa-pencil"></i>
                                                <span className="add-text">{!!primaryPhotoBlob || primaryDetails.ProfilePhoto ? "Update" : "Add"}</span>
                                            </div>
                                        </div>
                                        <div className="avatar__holder" style={{ backgroundImage: `url(${primaryPhotoBlob ? URL.createObjectURL(primaryPhotoBlob) : primaryDetails.ProfilePhoto ? primaryDetails.ProfilePhoto : defaultlogo})`, width: "130px", height: "140px" , backgroundSize: "cover", backgroundPosition: "center"}}>
                                        </div>
                                        {(!!primaryPhotoBlob || primaryDetails.ProfilePhoto) && (
                                            <span className="avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler}>
                                                <div className="pencil-container">
                                                    <i className="fa fa-times"></i>
                                                    <span className="add-text">Delete</span>
                                                </div>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center" style={groupDetails.MemberSignature ? { margin: '21px 32px auto' } : { margin: '21px 42px auto' }}>
                                    {/* Signature uploader */}
                                    <div className="kt-avatar kt-avatar--outline kt-avatar--changed offset-4" id="kt_user_add_avatar">                                              
                                        {(signaturePhotoBlob || groupDetails.MemberSignature) ? (
                                            <div>
                                                <div className="signature-text" >
                                                    <div className="avatar__holder" style={{ backgroundImage: `url(${signaturePhotoBlob ? URL.createObjectURL(signaturePhotoBlob) : groupDetails.MemberSignature})`, width: "130px", height: "35px" , backgroundSize: "cover", backgroundPosition: "center"}}>
                                                    </div>
                                                     {(!!signaturePhotoBlob || groupDetails.MemberSignature) && (
                                                    <span className="avatar__cancel_signature" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeSignatureHandler}>
                                                        <div className="pencil-container">
                                                            <i className="fa fa-times"></i>
                                                            <span className="add-text">Delete</span>
                                                        </div>
                                                    </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : ( 
                                            <button 
                                                type="button" 
                                                className="btn btn-default upload-button" 
                                                style={{ width: "130px", height: "35px" }} 
                                                onClick={handleOpenModal}
                                            >
                                                Signature
                                            </button>
                                        )}                     
                                        {/* {(signaturePhotoBlob || groupDetails.MemberSignature) ? <div className="avatar__holder" style={{ backgroundImage: `url(${signaturePhotoBlob ? URL.createObjectURL(signaturePhotoBlob) : groupDetails.MemberSignature && groupDetails.MemberSignature })`, width: "130px", height: "30px", backgroundSize: "cover", backgroundPosition: "center"}}></div> : <button type="button" className="btn btn-default upload-button" style={{width: "130px", height: "35px" }} onClick={openSignatureCropper}> Signature</button>} */}
                                        {/* {(!!signaturePhotoBlob || groupDetails.MemberSignature) && (
                                            <span className="avatar__cancel_signature" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeSignatureHandler}>
                                                <div className="pencil-container">
                                                    <i className="fa fa-times"></i>
                                                    <span className="add-text">Delete</span>
                                                </div>
                                            </span>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Date of Birth <span className="invalid-feedback">*</span></label>
                            <input type="date" className="form-control" id="inputDOB1" name="DOB" value={primaryDetails.DOB ? moment(primaryDetails.DOB).format('YYYY-MM-DD') : ''} onChange={(event) => updatePrimaryDetails('DOB', event.target.value)}/>

                        </div>
                        <div className="form-group">
                            <label>Gender <span className="invalid-feedback">*</span></label>
                            <select
                                value={primaryDetails.Gender || ''}
                                onChange={(event) => updatePrimaryDetails('Gender', event.target.value)}
                                style={{width: "100%", height: "30px"}}
                                className="form-control form-control2" 
                                id="selectGender1"
                            >   
                                <option defaultValue={'Select Option'}>Select Gender</option>
                                {genderOption.map((type, index) => (
                                    <option key={index} value={type.value}>{type.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Email <span className="invalid-feedback">*</span></label>
                            <input type="email" className="form-control" id="inputEmail" name="Email" value={primaryDetails.Email || ''} onChange={(event) => updatePrimaryDetails('Email', event.target.value)}/>

                        </div>
                        <div className="form-group">
                            <label>Mobile <span className="invalid-feedback">*</span></label>
                            <input type="text" maxLength="10" className="form-control" id="inputMobile" name="Mobile" value={primaryDetails.Mobile || ''} onChange={(event) => updatePrimaryDetails('Mobile', event.target.value)}/>
                            {!mobilePattern.test(primaryDetails.Mobile) && primaryDetails.Mobile != '' && <div className="invalid-feedback" >Please enter a valid 10-digit mobile number.</div>} {/* Display error message */}    

                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="text" maxLength="10" className="form-control" id="inputPhone" name="Phone" value={groupDetails.Phone || ''} onChange={(event) => updateGroupDetails('Phone', event.target.value)}/>
                            {!mobilePattern.test(groupDetails.Phone) && groupDetails.Phone != '' && <div className="invalid-feedback" >Please enter a valid 10-digit mobile number.</div>} {/* Display error message */}    

                        </div>
                        <div className="form-group">
                            <label  >Blood Group <span className="invalid-feedback">*</span></label>
                            <select
                                value={primaryDetails.BloodGroup || ''}
                                onChange={(event) => updatePrimaryDetails('BloodGroup', event.target.value)}
                                style={{width: "100%", height: "30px"}}
                                className="form-control form-control2" 
                                id="select"
                            >   
                                <option defaultValue={'Select Option'}>Select Blood Group</option>
                                {bloodGroupOption.map((type, index) => (
                                    <option key={index} value={type.value}>{type.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label >Address <span className="invalid-feedback">*</span></label>
                            <textarea id="inputAddress" name="Address" className="form-control height_address" rows="3" value={groupDetails.Address || ''} onChange={(event) => updateGroupDetails('Address', event.target.value)}></textarea>
                        </div>
                        <div className="form-group">
                            <label >Pan Card No </label>
                            <input type="text" className={`form-control `} id="inputPanNo" name="PanNo" style={{ textTransform: 'uppercase' }} value={groupDetails.PanNo || ''} onChange={(event) => updateGroupDetails('PanNo', event.target.value)}/>
                            {panError && <div className="invalid-feedback" >{panError}</div>} {/* Display error message */}

                        </div>
                        <div className="form-group">
                            <label >Aadhar Card </label>
                            <input type="text" className="form-control" id="inputAadharCard" name="AadharCard" maxLength="12" value={primaryDetails.AadharCardNo || ''} onChange={(event) => updatePrimaryDetails('AadharCardNo', event.target.value)}/>
                            {!aadharPattern.test(primaryDetails.AadharCardNo) && primaryDetails.AadharCardNo != '' && <div className="invalid-feedback" >Invalid Aadhar card. Please enter a valid Aadhar number.</div>} {/* Display error message */}    
                        </div>
                    </fieldset>
                </div>
                <FormFooter num={1}/>
             </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
            {signatureCropperData && (
                <ImageCropper
                    show           = { signatureCropperData }
                    header         = { signatureCropperData.header }   
                    imageRatio     = { signatureCropperData.imageRatio }
                    dataHandler    = { signatureCropperData.dataHandler }
                    onDismissModal = {() => setSignatureCropperData(null) }
                />
            )}
            {showModal && (
                <SignatureModal
                    show           = { showModal } 
                    onDismissModal = {() => setShowModal(false) }
                    setSignaturePhotoBlob = {setSignaturePhotoBlob}
                    openSignatureCropper = {openSignatureCropper}
                />
            )}
        </>
    )
};

export default PrimaryForm;