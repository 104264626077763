import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/PCGC_Login_logo.png';
import poweredBy from '../../assets/images/GolfEZ-powered_PNG.png';
import { NavLink } from 'react-router-dom';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { deviceDetect } from 'react-device-detect';
import { forgotPassword, loginWithOTP } from '../api/loginAPI';
import { setAuthState } from '../../assets/scripts/login-util';
import { checkVersion } from '../../assets/scripts/versionComparer';

const LoginWithOTP = (props) => {

    const [mobile,              setMobile]              = useState('');
    const [sessionId,           setSessionId]           = useState(0);
    const [code,                setCode]                = useState('');
    const [remember,            setRemember]            = useState(false);
 
    useEffect(() => {
        validationAttachments();
    }, []);
  
    const validationAttachments = () => {
        $('#ControlHolder').bootstrapValidator({
            fields: {
                txtUserMobile: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter mobile no.'
                        },
                        numeric: {
                            message: 'Number is required'
                        }
                    }
                },
                txtCode: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter OTP'
                        },
                        numeric: {
                            message: 'Number is required'
                        }
                    }
                }
            }
        });
    }

    const loginHandler = async (e, resend) => {
        e.preventDefault();

        $('#ControlHolder').data('bootstrapValidator').validate();

        if ($('#ControlHolder').data('bootstrapValidator').isValid()) {
            if(!!sessionId && !resend){
                
                if(!code){
                    swal.fire({
                        icon                  : 'warning',
                        titleText             : 'Error!',
                        text                  : `Please enter OTP`,
                        buttonsStyling        : false,
                        confirmButtonClass    : 'btn btn-brand',   
                    });
                    return
                }
                try {
                    swal.fire({
                        titleText           : 'Loading',
                        text                : 'Please Wait...',
                        showConfirmButton   : false,
                        allowOutsideClick   : false,
                        onOpen: () => {
                            swal.showLoading();
                        }
                    });

                    const deviceData = deviceDetect();
                    const device = await helper.getDeviceDetails(deviceData)
                    const response = await loginWithOTP(mobile,  sessionId, code, '', device,  remember);
    
                    swal.close();
    
                    setAuthState({
                        token           : response.Token,
                        user            : {id: response.User.ID, userName: response.User.FirstName + ' ' + response.User.LastName},
                        FE_VERSION      : response.FE_VERSION,
                        serverTimestamp : response.serverTimestamp,
                        expiresOn       : response.expiresOn
                    }, true);
                            
                    window.cookieChangeHandler();
                    checkVersion(response.FE_VERSION);
                    props.onLogin();
    
                } catch (err) {
                    swal.close();
                    swal.fire({
                        icon              : "error",
                        titleText         : "Error!",
                        text              : err.message
                        })
                    return;  
                }
            }else {
                try {
                    swal.fire({
                        titleText           : 'Loading',
                        text                : 'Please tait...',
                        showConfirmButton   : false,
                        allowOutsideClick   : false,
                        onOpen: () => {
                            swal.showLoading();
                        }
                    });
                    const response = await forgotPassword(mobile);
                    setSessionId(response.Session);
                    swal.close();
            
                } catch (err) {
                    swal.close();
                    swal.fire({
                        icon              : "error",
                        titleText         : "Error!",
                        text              : err.message
                        })
                    return;  
                }
            }
        }
    };

    return (
        <div className="container margin-t50">
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <div className="login-panel">
                        <NavLink to={'/login'}>
                            <img className="img-responsive img-login" src={bannerLogo} alt="" />
                        </NavLink>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Login</h3>
                        </div>
                        <div className="panel-body">
                            <div id="loginForm" role="form">
                                <div id="ControlHolder">
                                    <div className="form-group"> 
                                         <input 
                                            onChange={({ target }) => setMobile(target.value)}
                                            value={ mobile || '' }
                                            id="txtUserMobile" 
                                            name="txtUserMobile" 
                                            className="form-control" 
                                            type="text" 
                                            maxLength="10"
                                            disabled={!!sessionId}
                                            placeholder="Mobile Number" 
                                            autoFocus="autofocus" 
                                        />
                                    </div> 
                                    {!!sessionId && (
                                        <>
                                            <div className="form-group">
                                                <input 
                                                    onChange={({ target }) => setCode(target.value)}
                                                    value={ code || '' }
                                                    id="txtCode" 
                                                    name="txtCode" 
                                                    maxLength="6"
                                                    className="form-control" 
                                                    type="text" 
                                                    placeholder="Code" 
                                                    autoFocus="autofocus" 
                                                />
                                            </div>
                                            <div className="form-group" style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <label >
                                                    <input 
                                                        type="checkbox" 
                                                        className="ace" 
                                                        id="chkRemember" 
                                                        name="chkRemember" 
                                                        checked={remember}  // Controlled by the remember state
                                                        onChange={() => setRemember(!remember)}  // Toggle the state between true and false
                                                    />
                                                    <span className="lbl ml-2">Remember Me</span>  {/* Small margin-left for spacing */}
                                                </label>
                                                <a href='#' onClick={(e) => loginHandler(e, true)} className="lbl text-center">Resend OTP</a>  {/* Positioned to the right */}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="clearfix form-actions">
                                    <button 
                                        type="button" 
                                        id="btnLogin" 
                                        name="btnLogin" 
                                        onClick={(e) => loginHandler(e, false)}
                                        className="btn btn-lg btn-success btn-block">
                                            {!!sessionId ? 'Login' : 'Get OTP'}
                                    </button> 
                                    {/* OR separator with horizontal lines */}
                                    <div className='padding-tb10' style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ display: 'inline-block', width: '45%', borderTop: '1px solid #3B579D' }}></span>
                                        <div className='margin-lr10' style={{fontSize: '17px', color: '#3B579D'}}>OR  </div>
                                        <span style={{ display: 'inline-block', width: '45%', borderTop: '1px solid #3B579D' }}></span>
                                    </div>
                                    {/* Login with OTP button */} 
                                    <NavLink 
                                        to="/login" 
                                        className="btn btn-lg btn-block" 
                                        style={{ 
                                            backgroundColor: '#fff', 
                                            color: '#28a745', 
                                            textDecoration: 'none', // Green text, no underline
                                            textAlign: 'center',
                                            display: 'block', // Make it block-level for full width
                                            padding: '10px' // Add padding for button-like appearance
                                        }}
                                        onMouseEnter={(e) => e.target.style.textDecoration = 'none'}
                                        >
                                        Login With Password
                                    </NavLink>

                                    <br />
                                    <NavLink to={'/forgot-password'} className="lbl text-center">Forgot Password?</NavLink><br />
                                    <NavLink to={'/registration'} className="lbl text-center">No account yet? Create one</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="container-power-login">                        
                        <a className="power" href='https://golfezapp.com/' target="_blank">
                             <img src={poweredBy} alt=""/>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )

}

export default LoginWithOTP;