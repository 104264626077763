import react, {useState, useEffect} from 'react';


const TeeSheetModal = (props) => {

    const [bookingSheet, setBookingSheet] = useState([]);

    useEffect(()=>{               
        $('#modalBookSheet').modal();
        $('#modalBookSheet').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });  
               
        filterTeeSheet(1);  //default teebox 1
    }, []);

    const filterTeeSheet =(teeBox) => {
        if(props?.teeSheet){
            const sheet = props.teeSheet.filter(a => a.TeeBox == teeBox).map( s =>{
                const users  = props?.bookingUsers.filter(u => u.TeeTimeBookID == s.ID);                 
                s.users      = users;
                s.userString = users.map(u => `${u.UserName} (${u.UserType == 'User'? u.MembershipNo: 'G'})`).join(', ');
                return s;
            });

            setBookingSheet(sheet);            
        }
    }
  
    return(<>
         <div id="modalBookSheet" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header padding-b5">
                        {/* Tee Box */}
                        
                        <div id="pnlTeeBtn" className="row padding-lr10">
                            <div className="col-xs-6">
                                <button id="btnTeeBox1" type="button" className="btn btn-default btn-block btn-success"
                                    onClick={()=> filterTeeSheet(1)}
                                > 1st Tee </button>
                            </div>
                            <div className="col-xs-6">
                                <button id="btnTeeBox10" type="button" className="btn btn-default btn-block"
                                    onClick={()=> filterTeeSheet(10)}                                    
                                > 10th Tee </button>
                            </div>
                        </div>
                        <div className="row margin-b5 margin-t20">
                            <div className="col-xs-12 text-center">
                                <h6 className="margin-tb0">
                                    <i className="fa fa-calendar input-group-text margin-r5"></i><b id="bookingDate">{props?.selectedDate && (props.selectedDate)}</b>
                                </h6>
                            </div>
                        </div>
                    </div>          

                    <div className="modal-body padding-t0">
                        <div className="panel panel-default panel-club margin-t10">
                            <table id="tblBookSheet" className="table table-striped">                               
                                <tbody> 
                                    {(bookingSheet.length > 0) && bookingSheet.map((b, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="padding-lr20">
                                                    <div className="row">
                                                        <div className="col-xs-6">
                                                            <span><b> {b.Slot} </b></span>
                                                        </div>
                                                        <div className="col-xs-6 text-right">
                                                            {(b.Status)?(
                                                                <span>{(b?.BookingDate) && (moment(b.BookingDate).format('DD-MMM hh:mm:ss A'))} </span>
                                                            ):(                                                                
                                                                <span> Available <i className="fa fa-check-circle-o text-green fa-lg margin-lr10" aria-hidden="true"></i> </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    { (!!b.Status) && (
                                                        <div className="row">
                                                            <div className="col-xs-12"> 
                                                                <span> 
                                                                    {(b.Status == 'Booked')?(b.userString):(b.Status)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>    
                                    ))}                                 
                                </tbody>
                            </table>                       
                        </div>                  
                    </div>                  
                </div>
            </div>
        </div>
    </>)
}

export default TeeSheetModal