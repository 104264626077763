import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { getOrderByPaymentOrderID, getPaymentOrderID } from '../api/paymentAPI';
import Helmet from 'react-helmet';

const Invoice = (props) => {

    const [invoice,        setInvoice]          = useState();

    const queryParameters = new URLSearchParams(window.location.search);
    const paymentOrderID = queryParameters.get("id");
    const linkId = queryParameters.get("linkId");

    useEffect(() => {

        if (linkId) {
            fetchPaymentOrderID(linkId);
        } else if (paymentOrderID) {
            verifyHandler(paymentOrderID);
        } else {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : "Error: Invoice not found",
                showConfirmButton   : false,
                allowOutsideClick   : false
            })
        }
    }, []);

    const verifyHandler = async (paymentOrderID) => {
        try {
            Swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    Swal.showLoading();
                }
            });
            const response = await getOrderByPaymentOrderID(paymentOrderID);
            setInvoice(response.InvoiceData);
            Swal.close();
        } catch (err) {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : err.message,
                showConfirmButton : false,
                allowOutsideClick : false
            })
            return;  
        }
    }

    const fetchPaymentOrderID = async (linkId) => {
        try {
            Swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    Swal.showLoading();
                }
            });
            const response = await getPaymentOrderID(linkId);
            verifyHandler(response.PaymentOrderID);
            queryParameters.set("id", response.PaymentOrderID);
            queryParameters.delete("linkId");
            const newUrl = `${window.location.pathname}?${queryParameters.toString()}`;
            window.history.pushState(null, '', newUrl);
            Swal.close();
        } catch (err) {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : err.message,
                showConfirmButton : false,
                allowOutsideClick : false
            })
            return;  
        }
    }

    return(
        <div>
            <Helmet>
                <meta name="viewport" content="" />
            </Helmet>
            <div dangerouslySetInnerHTML={{ __html: invoice }}/>
        </div>
    )
}

export default Invoice;