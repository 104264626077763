import { axiosGet } from "./axiosFunctions";

const getOrderByPaymentOrderIDUrl                            = '/api/payment/getOrderByPaymentOrderID';
const getPaymentOrderIDUrl                                   = '/api/payment/getPaymentOrderID';


export const getOrderByPaymentOrderID = async (paymentOrderID) => {   
    const response = await axiosGet(getOrderByPaymentOrderIDUrl, { headers: '', params: {paymentOrderID}});
    return response;
}

export const getPaymentOrderID = async (linkId) => {   
    const response = await axiosGet(getPaymentOrderIDUrl, { headers: '', params: {linkId}});
    return response;
}