import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/PCGC_Login_logo.png';
import poweredBy from '../../assets/images/GolfEZ-powered_PNG.png';
import { NavLink, useHistory } from 'react-router-dom';
import { forgotPassword } from '../api/loginAPI';
import Swal from 'sweetalert2';

const Forgot = (props) => {

    const [mobile,     setMobile]           = useState('');
    const history 				            = useHistory();

    useEffect(() => {
        validationAttachments();
    }, []);

    const validationAttachments = () => {
        $('#ControlHolder').bootstrapValidator({
            fields: {
                txtMobile: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter mobile no.'
                        },
                        numeric: {
                            message: 'Number is required'
                        },
                        stringLength: {
                            max: 10,
                            min: 10,
                            message: 'Enter a 10 digit valid mobile number'
                        }
                    }
                }
            }
        });
    }

    const forgotHandler = async (e) => {
        e.preventDefault();

        $('#ControlHolder').data('bootstrapValidator').validate();
        if ($('#ControlHolder').data('bootstrapValidator').isValid()) {

            try {
                Swal.fire({
                    titleText           : 'Loading',
                    text                : 'Please Wait...',
                    showConfirmButton   : false,
                    allowOutsideClick   : false,
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
                const response = await forgotPassword(mobile);
                Swal.close();

                $.session.set('Code', response.Session);
                $.session.set('Mobile', response.User.Mobile);
                $.session.set('UserID', response.User.ID);
                history.push('/reset-password');

            } catch (err) {
                Swal.close();
                Swal.fire({
                    icon              : "error",
                    titleText         : "Error!",
                    text              : err.message
                  })
                return;  
            }
            
        }
    };

    return (
        <div className="container margin-t50">
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <div className="login-panel">
                        <NavLink to={'/login'}>
                            <img className="img-responsive img-login" src={bannerLogo} alt="" />
                        </NavLink>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Forgot Password</h3>
                        </div>
                        <div className="panel-body">
                            <div id="forgotForm" role="form">
                                <div id="ControlHolder">
                                    <span className="lbl">Enter your mobile number to reset password</span><br />
                                    <br />
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => setMobile(target.value)}
                                            value={ mobile || '' }
                                            id="txtMobile" 
                                            name="txtMobile" 
                                            className="form-control" 
                                            type="text" 
                                            placeholder="Mobile Number" 
                                            autoFocus="autofocus" 
                                        />
                                    </div>
                                </div>
                                <div className="clearfix form-actions">
                                    <button 
                                        type="button" 
                                        id="btnContinue" 
                                        name="btnContinue" 
                                        onClick={forgotHandler}
                                        className="btn btn-lg btn-success btn-block">Continue
                                    </button><br />
                                    <NavLink to={'/login'} className="lbl text-center">Back to login</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="container-power-login">                        
                        <a className="power" href='https://golfezapp.com/' target="_blank">
                             <img src={poweredBy} alt=""/>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )

}

export default Forgot;